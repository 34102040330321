.ButtonBox {
    margin-top: 2vh;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    padding-top: 20px;
    padding-bottom: 20px;
}

.btn-large-main {
    width: 150px !important;

    font-size: 17px !important;
    font-weight: 500;
    margin-top: 4px;
    margin-left: 6px;
    margin-right: 6px;
}
