.btn-large-rent {
    font-weight: 600 !important;
    font-size: 14px;
}

.marginBottom {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.noMargin {
    margin: 0px !important;
}

.noMarginBot {
    padding-top: 10px !important;
    margin-bottom: 0px !important;
}

.noPaddingTop {
    padding-top: 0px !important;
}
