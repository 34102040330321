.gridWrapper {
    display: grid;

    justify-content: space-around;
    grid-template-columns: repeat(auto-fill, minmax(360px, 400px));

    grid-gap: 20px;
}

.gridWrapperOneColumn {
    display: grid;

    justify-content: space-around;
    grid-template-columns: 1fr;

    grid-gap: 20px;

    width: 100%;
    max-height: 240px;
}

.gridBox {
    background-color: rgb(85, 77, 77);
    color: #fff;
    border-radius: 5px;

    border: 3px solid black;

    box-shadow: rgb(38, 57, 77) 0px 5px 15px 5px;
    max-height:  240px;
}

.flexContainerProperty {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
}

.descriptionFlex {
    width: 50%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;

    text-align: center;
    padding: 5px;
}

.imageFlex {
    width: 50%;
    height: 100%;
}

.rent-table tbody tr td {
    padding: 3px !important;
    padding-right: 10px !important;
}

.imageFlex img {
    box-shadow: rgb(13, 19, 26) 0px 0px 2px 1px;
    object-fit: fill;
    width: 100%;
    height: 100%;
}

.propertyTitle {
    font-size: 24px;
    margin: 0px;
    font-weight: 600;
    overflow: hidden;
}

.propertyDescription {
    font-size: 18px;
    margin: 0px;
    font-weight: 600;
}

.mortage {
    box-shadow: none;
    box-shadow: rgb(192, 0, 0) 0px 0px 10px 5px;

    border: none;
}

.Grey {
    background-color: rgba(166, 166, 166);
    color: rgb(39, 35, 39);
}

.Black {
    background-color: rgba(19, 17, 17);
}

.White {
    background-color: rgba(243, 243, 243);
    color: rgb(37, 28, 28);
}

.Green {
    background-color: rgba(107, 255, 0);
    color: rgb(48, 45, 45);
}

.Light_Blue {
    background-color: rgba(70, 240, 240);
    color: rgb(51, 44, 44);
}

.Violett {
    background-color: rgba(240, 50, 230);
}

.Orange {
    background-color: rgba(243, 117, 14, 0.911);
    color: rgb(51, 44, 44);
}

.Red {
    background-color: rgba(255, 28, 28);
}

.Light_Green {
    background-color: rgba(0, 255, 153);
    color: rgb(51, 44, 44);
}

.Dark_Red {
    background-color: rgba(128, 0, 0);
}

.Yellow {
    background-color: rgba(255, 255, 0);
    color: rgb(51, 44, 44);
}

.Cyan {
    background-color: rgba(0, 128, 128);
}

.Dark_Violett {
    background-color: rgba(128, 0, 128);
}

.Dark_Green {
    background-color: rgba(0, 120, 0);
}

.Blue {
    background-color: rgba(0, 0, 255);
}
