.playerCard {
    border-left: 2px solid rgb(179, 137, 113);
    border-right: 2px solid rgb(179, 137, 113);
    border-top: 2px solid rgb(179, 137, 113);

    border-radius: 15px 15px 0px 0px;

    box-shadow: rgb(0, 7, 15) 0px 0px 13px 10px,
        inset rgb(0, 7, 15) 0px 0px 2px 1px;

    background: rgba(253, 234, 221, 0.986);
    user-select: none;
}

.isBroke {
    box-shadow: none;
    box-shadow: rgb(192, 0, 0) 0px 0px 15px 10px;
    border-radius: 15px 15px 0px 0px;

    border: none;
}

h4 {
    font-size: 25px;
}

td {
    padding-top: 5px !important;
    padding-bottom: 2px !important;
}

.valuePart {
    text-align: end;
    margin-left: 5px;
    padding-right: 10px;
}

h4 {
    margin: 0px;
    word-wrap: break-word;
    vertical-align: middle;
}

.centerRow {
    display: flex;
    align-items: center;
}

.cardTitle {
    height: 32px;
}

.animationIncrease {
    animation: increase 3s ease;
    transition: 0.2s;
}

.animationDecrease {
    animation: decrease 3s ease;
    transition: 0.2s;
}

@keyframes increase {
    0% {
    }
    25% {
        background-color: rgb(107, 184, 36);
        padding-right: 20px;
        border-radius: 15px 0px 0px 15px;
    }
    50% {
        background-color: rgb(107, 184, 36);
        border-radius: 15px 0px 0px 15px;
    }
    100% {
    }
}

@keyframes decrease {
    0% {
    }
    25% {
        background-color: rgb(238, 23, 23);
        padding-right: 20px;
        border-radius: 15px 0px 0px 15px;
    }
    50% {
        background-color: rgb(238, 23, 23);
        border-radius: 15px 0px 0px 15px;
    }
    100% {
    }
}
