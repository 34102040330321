.stickBottom {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.carousel-item,
.carousel {
    opacity: 1 !important;
    height: 260px !important;
}

.carousel-item {
    width: 240px;
}
