form {
    padding-bottom: 5px !important;
}

.playerList {
    max-height: 300px;
    padding: 5px;
    overflow: auto;
    overflow-x: hidden;
}

.textPart {
    height: 60px !important;
    line-height: 60px;
    height: 60px;
}

.textPart span {
    display: table-cell;
    vertical-align: middle;
}

.name {
    font-size: calc(0.5vw + 1.2vh + 0.5vmin) !important;
    color: whitesmoke;
}

[type="radio"]:not(:checked) + span:before {
    border: 2px solid white !important;
}

.Seller [type="radio"]:checked + span:after,
.Renter [type="radio"]:checked + span:after,
.Sender [type="radio"]:checked + span:after {
    background-color: red !important;
    border: none;
}

.Owner [type="radio"]:checked + span:after,
.Receiver [type="radio"]:checked + span:after {
    background-color: rgb(53, 219, 11) !important;
    border: none;
}

.Buyer [type="radio"]:checked + span:after {
    background-color: rgb(54, 197, 19) !important;
    border: none;
}

.Player [type="radio"]:checked + span:after,
.Customer [type="radio"]:checked + span:after {
    background-color: rgb(219, 181, 9) !important;
    border: none;
}

.smallRow {
    margin: 0px !important;
}

.paddingBot {
    padding-bottom: 10px;
}

.centerRow {
    margin: 0px;
}
