.modalCart {
    height: 300px !important;
    width: 200px !important;
}

.carousel-item-small,
.carousel-small {
    height: 250px !important;
}

.carousel-item-small {
    width: 340px !important;
    height: 250px !important;
    padding: 20px !important;
    user-select: none;
}

.fullHeigth {
    display: block;
    height: 50px;
    overflow: auto;

    padding-bottom: 5px !important;
    font-size: 15px;

    max-height: 75vh !important;
}

.thead-scrollable,
.tbody-scrollable tr {
    display: table;
    width: 100%;
    table-layout: fixed; /* even columns width , fix width of table too*/
    font-weight: 700;
}

.thead-scrollable {
    width: calc(100% - 1em);
}

.thead-scrollable th {
    padding: 10px !important;

    font-weight: 700;
}

.tableContainer {
    margin-bottom: 20px !important;

    padding-left: 10px !important;
}
.table-scrollable {
    width: 100% !important;
}

.align-left {
    text-align: start !important;
}
.align-right {
    text-align: end !important;
}
.align-center {
    text-align: center !important;
}

.marginTop {
    margin-top: 50px;
}

.descriptionBox {
    width: 45% !important;
}

.collapsible-body {
    width: 100%;
    padding: 0px;
}

.sectionDescription {
    margin: 0px !important;
}
