.smallRow {
    margin: 0px !important;
    margin-bottom: 10px !important;
    padding-top: 15px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.padding10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.btn-large-buy,
.btn-large-type {
    font-size: 14px !important;
    font-weight: 600;
    margin-top: 4px;
    margin-left: 6px;
    margin-right: 6px;
    padding: 0px !important;
    padding-right: 20px !important;
    padding-left: 5px !important;
}
.btn-large-type {
    width: 90% !important;
}

.btn-large-buy {
    width: 70% !important;
}

.centerRow {
    margin: 0px;
}

.flexContainer {
    display: flex;
    padding: 0px 5px 15px 5px;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.flexItem-Preview {
    flex-grow: 1;
    height: 160px;
    padding: 5px;
}

.buttonBox {
    width: 134px;
    align-self: center;
}

.buttonFlex {
    width: 134px;
    height: 164px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.buttonFlex .btn {
    width: 134px !important;
}
