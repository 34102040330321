.wallpaper {
    background-image: url("../images/Skyline1.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;

    height: 100vh;
}

.cardColor-pay {
    background-color: #1d1d1db2 !important;
}

.cardColor-buy {
    background-color: #212122b2 !important;
}

.cardColor-main {
    background-color: #131111d8 !important;
}

.cardColor-lobby {
    background-color: #1a1818d5 !important;
}

.cardColor-overview {
    background-color: #3a3535ee !important;
}

.card {
    border-radius: 10px !important;
    box-shadow: 0px 2px 25px 10px black;
}

.card-title {
    font-size: calc(00.8vw + 1.6vh + 1.2vmin) !important;
    font-weight: 400 !important;
    margin: 0px !important;
}

.card-content {
    margin: 0px !important;
    padding: 0px !important;
}

.modal {
    width: 90% !important ;
    max-height: 80% !important;
}

.icon {
    filter: drop-shadow(0 3px 0.7rem rgb(19, 2, 4));

    width: 64px;
    height: 64px;
}

.iconSmall {
    filter: drop-shadow(0 0 0.75rem rgb(219, 201, 204));

    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 56px;
    height: 56px;
}

.toast {
    font-size: 18px !important;
    font-weight: 600;
}

/* custom scrollbar */
::-webkit-scrollbar {
    width: 20px;
}

::-webkit-scrollbar-track {
    background-color: rgb(51, 50, 50);
}

.playerList::-webkit-scrollbar-track {
    background-color: transparent !important;
}

::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}