.bankImg {
    filter: drop-shadow(0 0 1rem rgb(88, 83, 83));
    height: auto;
    max-height: 190px !important;
}

.btn-large-amount {
    width: 60% !important;
    font-weight: 600 !important;
}
